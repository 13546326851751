<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input v-model="params.userName" allowClear size='small' style="width:150px;" placeholder='userId或用户昵称'></a-input>
        <a-input v-model="params.keyword" allowClear size='small' style="width:150px;" placeholder='搜索关键词'></a-input>
        <a-select
            allowClear
            v-model="params.type"
            size='small'
            style="width:100px;margin:5px;"
            placeholder='搜索类型'
        >
          <a-select-option value="1">价格搜索</a-select-option>
          <a-select-option value="2">银元搜索</a-select-option>
          <a-select-option value="3">商品搜索</a-select-option>
        </a-select>
        <a-select
            allowClear
            v-model="params.resultSize"
            size='small'
            style="width:100px;margin:5px;"
            placeholder='筛选结果'
        >
          <a-select-option value="0">结果为0</a-select-option>
          <a-select-option value="5">结果小于5</a-select-option>
        </a-select>
        <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
      </div>
      <div>
        <!-- <a-button @click="openModal({})" size='small'>新增</a-button> -->
      </div>
    </div>
    <div class="table-content" >
      <a-table :pagination='false' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
        <div slot="Num" slot-scope="row">
          <a @click="openPage(row)">{{row.releaseProductNum}}</a>
        </div>
        <div
            class="user-name"
            slot="uerNameSlot"
            slot-scope="row"
            @click="handleGoToSearchHistory(row)"
        >
          {{ row.userName }}
        </div>

<!--     关键词   -->
        <div slot="queryKeywordSlot" slot-scope="row">
          <div v-if="row.addressType !== 1">{{ row.keyword }}</div>
          <div v-else @click="handleGoToDataQuery(row)" class="color-blue cur-pot">{{ row.keyword }}</div>
        </div>

        <div slot='set' slot-scope="row">
          <a @click="openModal(row)" class="map-margin">编辑</a>
          <a class="map-margin" @click="remove(row)">删除</a>
        </div>

      </a-table>
      <div style="display: flex;justify-content: flex-end">
        <a-button @click="handleChangePage('up')">上一页</a-button>
        <a-button @click="handleChangePage('down')" class="ml-10">下一页</a-button>
      </div>
    </div>
    <a-modal @ok='hideModal' @cancel='close' v-model="visible" :title="IO === 1 ? '编辑' : '新增'">
      <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
        <a-form-model-item label='选择用户' prop='userId'>
          <a-select  @search='getUsers' v-model="forms.userId" placeholder='选择用户' :filter-option="untils.filterOption" showSearch style="width:100%;margin-bottom:20px;">
            <a-select-option v-for="item of user_list" :key="item.id" :value='item.id'>
              <div style="display:flex;align-items:center;">
                <img style="max-width:20px;max-height:20px;border-radius:50%;margin-right:10px;" :src="item.userAvatar" alt="">
                <span :text='item.userName'>{{item.userName}}</span>
              </div>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='商品数量' prop='releaseProductNum'>
          <a-input placeholder='请输入商品数量' v-model="forms.releaseProductNum"></a-input>
        </a-form-model-item>
        <a-form-model-item label='排序' prop='sort'>
          <a-input placeholder='请输入排序号' v-model="forms.sort"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data(){
    return {
      columns:[
        {title:'用户', scopedSlots: { customRender: "uerNameSlot" }},
        {title:'搜索词', scopedSlots: { customRender: 'queryKeywordSlot'}, width: 200, align: 'left'},
        {title:'搜索类型',dataIndex:'addressType',
          customRender:(row)=>{
            const inner = row;
            if (inner == 1 ) {
              return <span style="color:green">成交价</span>;
            }
            if (inner == 2) {
              return <span style="color:red">银元</span>;
            }
            if (inner == 3) {
              return <span style="color:blue">商品</span>;
            }
          }
        },
        {title:'排序方式',dataIndex:'sortType',
          customRender:(row)=>{
            const inner = row;
            if (inner == 0 ) {
              return <span style="color:green">默认</span>;
            }
            if (inner == 1) {
              return <span style="color:green">价格升序</span>;
            }
            if (inner == 2) {
              return <span style="color:green">价格降序</span>;
            }
            if (inner == 3) {
              return <span style="color:green">时间升序</span>;
            }
            if (inner == 4) {
              return <span style="color:green">时间降序</span>;
            }
          }
        },
        {title:'搜索结果数',dataIndex:'resultSize'},
        {title:'搜索时间',dataIndex:'createTime'},
        // {title:'操作',align:'center',scopedSlots: { customRender: 'set' },},
      ],
      data:[],
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      rules:{
        userId:[{ required: true, message: '请选择用户', trigger: 'change' }],
        releaseProductNum:[{ required: true, message: '请输入', trigger: 'change' }],
        sort:[{ required: true, message: '请输入', trigger: 'blur' }],
      },
      forms:{},
      visible:false,
      params:{
        pageSize:10,
        userName:'',
        userPhone:'',
        resultSize: undefined,
        pageType: '',
        startId: 0
      },
      pagination:{
        total:0,
        current:1,
        pageSize: 30,
        showTotal:(total)=>`共${total}条`,
      },
      loading:false,
      IO:0,
      user_list:[],
      maxId: 0,
      minId: 0,
    }
  },
  created(){
    const { userId } = this.$route.query;
    this.$set(this.params, "userName", userId);
    this.getData(this.params)
  },
  methods:{
    /** 前往数据查询页面 */
    handleGoToDataQuery(row) {
      const origin = location.origin
      const url = `${origin}/#/cms/guqianbi/goods?queryKeyword=${row.keyword}`;
      window.open(url)
    },
    /** 操作*/
    async handleChangePage(type) {
      this.params.pageType = type
      if (type === 'up') {
        this.params.startId = this.maxId
      } else {
        this.params.startId = this.minId
      }
      await this.getData(this.params)
    },
    /** 去往搜索历史 */
    handleGoToSearchHistory(row) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/recordsearchhistory?userId=${row.userId}`;
      window.open(url)
    },
    async getData(params){
      this.loading = true
      const res = await this.axios('/dq_admin/keyworldSearchRecord/getSearchLog',{params:params})
      this.loading = false

      let list = res.data;
      this.data = list;
      this.maxId = list[0].id
      this.minId = list[list.length - 1].id
    },
    search(){
      this.params.startId = 0
      this.params.pageType = ''
      this.getData(this.params)
    },

    openPage(row){
      const routeData = this.$router.resolve({
        path: "/cms/currencymannage/goods",
        query: {
          userId:row.userId||100
        }
      });
      window.open(routeData.href, '_blank');
    },
    async remove(row){
      const {id} = row
      const res = await this.axios.post('/dq_admin/recommendUser/del',{id})
      if(res.status == 200){
        const {message} = res
        this.$message.success(message)
        this.getData(this.params)
      }
    },
    openModal(row){
      const {id} = row || {id:''}
      if(id){
        this.IO = 1
        const proxyObj = JSON.parse(JSON.stringify(row))
        this.forms = proxyObj
      }else{
        this.IO = 0
        this.forms = {}
      }
      this.visible = true
    },
    async getUsers(inner){
      const res = await this.axios('/dq_admin/keyworldSearchRecord/getSearchLog',{params:{userName:inner,pageSize:30}})
      if(res.status == 200){
        const {data} = res
        this.user_list = data
      }
    },
    close(){
      this.resetForms()
      this.visible = false
    },
    hideModal(){
      this.$refs.ruleForm.validate(valid => {
        if(valid){
          this.submit(this.forms)
        }else{
          return false
        }
      });
    },
    async submit(row){
      const res = await this.axios.post('/dq_admin/recommendUser/addOrEdit',row)
      if(res.status == 200){
        const {message} = res
        this.$message.success(message)
        this.getData(this.params)
        this.close()
      }
    },
    resetForms(){
      this.forms = {}
      this.$refs.ruleForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped>
.user-name {
  color: #2d8cf0;
  text-decoration: underline;
  cursor: pointer;
}
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
  .table-content{
    flex:1;
    overflow-y: auto;
  }
}
.take-box{width:100%;max-height:700px;}
</style>